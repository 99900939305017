<template>
  <div class="list">

    <div class="list__wrapper">
      <div class="item" v-for="item in products" :key="item.id">
        <!-- Image preview section-->
        <section class="item__thumbnail">

          <img v-if="item.image" :src="item.image || '@/assets/no-photo-big.svg'" alt="">
          <img v-if="!item.image" src="@/assets/no-photo-big.svg" alt="">
        </section>
        <!-- Description section -->
        <section class="item__description">
          <h3 class="item__name text-16-500">{{ item.name }}</h3>
          <p class="item__feature">
            <span>{{ $t('category') }}:</span>
            <span>{{ item.category ? item.category.name : '' }}</span>
          </p>
          <p class="item__feature">
            <span>{{ $t('listBrand') }}:</span>
            <span>{{ item.brand ? item.brand.name : '' }}</span>
          </p>
          <p class="item__feature">
            <span>{{ $t('models.type') }}:</span>
            <span>{{ item.type ? item.type.name : '' }}</span>
          </p>
        </section>
        <!-- Control section -->
        <section class="item__control">
          <simple-button
            :value="$t('useModel')"
            :disabled="item.category === null"
            @click="$router.push(`/create/product/${item.id}#new`)"
          />
        </section>
      </div>
    </div>

  </div>
</template>

<script>
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
export default {
  components: {
    SimpleButton,
  },
  props: {
    products: Array,
  },
}
</script>

<style lang="scss" scoped>

$gutter: 20px;

.list {
  font-size: 14px;

  &__wrapper {
    display: grid;
  }
}

.item {
  height: 180px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 200px auto 200px;
  grid-gap: $gutter * 2;

  padding-bottom: $gutter;
  margin-bottom: $gutter;

  border-bottom: 1px solid #E5E5E5;

  &__thumbnail {
    //background-color: #000;
    display: block;
    height: 100%;
    max-height: 160px;

    img {
      max-width: 100%;
      height: 100%;
    }
  }

  &__control {
    align-self: end;
  }

  &__name {
    padding-bottom: 30px;
  }

  &__feature {
    padding-bottom: 12px;

    span:first-child {
      color: #999999;
    }
  }

}
</style>
