<template>
  <div class="field-wrap">
    <form action="" class="filter">
      <div class="filter-field categories">
        <input
          type="text"
          class="input base-input"
          :placeholder="$t('category')"
          disabled
          :value="selectedCategoryLabel"
        >
        <div class="filter-field__inner">
          <CloseButton
            class="reset"
            v-if="selectedCategory.id"
            @onClick="resetCategory"
          />
          <button
            class="filter-field__menu"
            type="button"
            @click="isShowCategoryModal = true"
          >
            <img src="@/assets/vertical-menu.svg" alt="">
          </button>
        </div>
      </div>
      <div class="filter-field search">
        <input
          type="text"
          class="input base-input input--search-by-name"
          :placeholder="$t('models.search')"
          @input="updateFilters"
          v-model="filters.term"
        >
        <button class="filter-field__btn filter-field__btn--search" type="button">
          <img src="@/assets/search.svg" alt="">
        </button>

        <CloseButton
          class="reset"
          v-if="filters.term"
          @onClick="resetSearchField"
        />
      </div>
      <div class="filter-field brand">
        <base-select
          :options="brandList"
          :placeholder="$t('listBrand')"
          @search="onKeydownBrands"
          @input="onSelectBrand"
        />
      </div>
      <div class="filter-field model">
        <base-select
          :options="typesList"
          :disabled="!selectedCategoryLabel"
          :placeholder="$t('models.type')"
          @search="onKeydownTypes"
          @input="onSelectType"
        />
      </div>
    </form>

    <SearchCategoryModal
      ref="categoryModal"
      :isShow="isShowCategoryModal"
      @onClose="isShowCategoryModal = false"
      @onSubmit="onSelectCategory"
    />

  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'
import SearchCategoryModal from '@/components/pages/search-product/SearchCategoryModal'
import CloseButton from '@/components/ui/forms/buttons/CloseButton'

export default {
  name: 'TheProductsFilterForm',
  components: {
    CloseButton,
    SearchCategoryModal,
  },
  data () {
    return {
      isShowCategoryModal: false,
      filters: {
        term: null,
        brandId: null,
        categoryId: null,
        typeId: null,
      },
      searchText: '',
      selectedCategory: {},
    }
  },

  methods: {
    updateFilters: debounce(function () {
      this.$emit('updateFilters', this.filters)
    }, 400),

    resetCategory () {
      this.selectedCategory = {}
      this.$refs.categoryModal.reset()
      this.filters.categoryId = null
      this.updateFilters()
    },

    resetSearchField () {
      this.filters.term = ''
      this.updateFilters()
    },

    onSelectBrand (value) {
      if (!value) {
        this.filters.brandId = null
      } else {
        this.filters.brandId = value.id
      }
      this.updateFilters()
    },

    onKeydownTypes: debounce(async function (search) {
      if (this.filters.categoryId) {
        await this.$store.dispatch('getType', {
          categoryId: this.filters.categoryId,
          name: search,
        })
      }
    }, 1000),

    onKeydownBrands: debounce(async function (search) {
      await this.$store.dispatch('getBrand', search)
    }, 1000),

    search: debounce(async (loading, search, cb) => {
      await cb.call(this, search)
      loading(false)
    }, 350),

    onSelectType (value) {
      if (!value) {
        this.filters.typeId = null
      } else {
        this.filters.typeId = value.id
      }
      this.updateFilters()
    },

    async onSelectCategory (category) {
      this.isShowCategoryModal = false
      this.selectedCategory = category
      this.filters.categoryId = category.id
      await this.$store.dispatch('getType', {
        categoryId: category.id,
      })
      this.updateFilters()
    },
  },

  computed: {
    ...mapState({ brands: state => state.products.brand.items }),
    ...mapState({ categories: state => state.products.categories }),
    ...mapState({ types: state => state.products.types.items }),
    selectedCategoryLabel () {
      if (!this.selectedCategory.title) {
        return null
      }
      const { title } = this.selectedCategory
      return title.length > 19
        ? title.slice(0, 16).trim() + '...'
        : title
    },
    typesList () {
      return this.types.map(type => {
        return {
          id: type.id,
          label: type.name,
        }
      })
    },
    brandList () {
      return this.brands.map(brand => {
        return {
          id: brand.id,
          label: brand.name,
        }
      })
    },
  },

  async mounted () {
    await this.$store.dispatch('getBrand', '')
  },
}
</script>

<style lang="scss" scoped>

.filter {
  display: grid;
  grid-template-columns: 3fr 7fr 3fr 3fr;
  align-items: center;
  width: 100%;

  &-field {
    position: relative;

    &.categories {
      input {
        border-radius: 8px 0 0 8px;
        border-width: 1px 0 1px 1px;
        &:disabled {
          background-color: #fff;
        }
      }
    }

    &.search {
      .reset {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.brand {
      :deep(.vs__dropdown-toggle) {
        border-radius: 0 !important;
        border-width: 1px 0;
      }
    }

    &.model {
      :deep(.vs__dropdown-toggle) {
        border-radius: 0 8px 8px 0 !important;
        border-width: 1px;
      }
    }

    &__inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 20px;
      @include flex(row, flex-end, center);
    }

    &__menu {
      cursor: pointer;
      border: none;
      background: transparent;
      outline: none;
      padding: 0;
      margin: 0;

      img {
        display: block;
      }
    }

    &__btn {
      position: absolute;
      border: none;
      background-color: transparent;
      outline: none;

      &--search {
        left: 20px;
        top: 55%;
        transform: translateY(-50%);
      }
    }
  }
}

.input {
  &--search-by-name {
    padding-left: 50px;
  }
}

.category-brand__field {
  width: 100%
}
</style>
