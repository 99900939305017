<template>
  <div class="main-content">
    <base-bread-crumbs />

    <div class="main-content__inner">
      <div class="page-title">{{ $t('newProdCreation') }}</div>

      <div class="page-description">
        In 75% of cases, sellers find their models in our database.<br/>
        If you still haven't found your product in our database, use the “Add new model” button.
      </div>

      <TheProductsFilterForm
        @updateFilters="updateFilters($event)"
      />

      <div class="grid">
        <SearchProductTrigger
          @onClick="$router.push('/create/model')"
        />

        <div
          class="products"
          v-if="model.items.length || model.pagination.pages"
        >

          <div class="products__total">
            <span>{{ `${model.pagination.total} ${$t('models.total')}` }} </span>
          </div>

          <SearchProductList
            :products="model.items"
          />

          <ListPagination
            :pagination="model.pagination"
            @onSelectPage="updatePage($event)"
          />
        </div>
        <div class="products" v-else>
          {{ $t('noItems') }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs'
import TheProductsFilterForm from '@/components/pages/search-product/TheProductsFilterForm'
import SearchProductTrigger from '@/components/pages/search-product/SearchProductTrigger'
import SearchProductList from '@/components/pages/search-product/SearchProductList'
import ListPagination from '@/components/ui/tables/pagination/ListPagination'
import { mapState } from 'vuex'

export default {
  name: 'SearchProduct',
  components: {
    ListPagination,
    SearchProductList,
    SearchProductTrigger,
    TheProductsFilterForm,
    BaseBreadCrumbs,
  },

  data () {
    return {
      products: [],
      pagination: Object,
      filters: {
        page: 1,
        term: null,
        brandId: null,
        categoryId: null,
      },
    }
  },

  methods: {
    async getModels () {
      await this.$store.dispatch('getAllModels', this.filters)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },

    async updatePage (pageIndex) {
      this.pagination.page = pageIndex
      this.filters.page = pageIndex
      await this.getModels()
    },

    async updateFilters (filters) {
      this.filters.page = 1
      Object.assign(this.filters, filters)
      await this.getModels()
    },
  },

  computed: {
    ...mapState({ model: state => state.products.model }),
  },

  async created () {
    await this.getModels()
  },
}
</script>

<style lang="scss" scoped>

.grid {
  margin: 25px 0;

  display: grid;
  grid-template-columns: 3fr 13fr;
  align-items: start;

}

.products {
  padding-left: 35px;

  &__total {
    color: #999999;
    margin-bottom: 14px;
  }
}

</style>
