<template>
  <div class="trigger">
    <p class="trigger-label h4">{{ $t('models.creationText') }}</p>
    <button class="base-button button" type="button" @click="$emit('onClick')">
      {{ $t('models.create') }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.trigger {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  min-height: 165px;
  box-sizing: border-box;
  padding: 24px 15px;
  background: $color-grey;
  border-radius: 20px;
  color: $text-black;

  &-label {
    margin-bottom: 16px;
  }
}
</style>
